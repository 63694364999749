function IDCheck(value) {
  if (value) {
    if (value.length === 8) {
      var id3 = value.slice(-1)
      var id2 = value.slice(-7, -1)
      var id1 = value.slice(0, 1)
    } else if (value.length === 9) {
      var id3 = value.slice(-1)
      var id2 = value.slice(-7, -1)
      var id1 = value.slice(0, 2)
    } else {
      return false
    }
  
    let reg1 = /[a-zA-Z]{1,2}/
    if (!reg1.test(id1) || id1.length > 2) {
      return false
    }
  
    let reg2 = /\d{6,6}/
    if (!reg2.test(id2) || id2.length !== 6) {
      return false
    }
  
    let sum = 0
    let num
    if (id1.length === 1) {
      sum = 324 // 头一个当作空格，36 * 9 = 324
      sum += (id1.toUpperCase().charCodeAt() - 55) * 8
    } else {
      sum = (id1.substr(0, 1).toUpperCase().charCodeAt() - 55) * 9
      sum += (id1.substr(1, 1).toUpperCase().charCodeAt() - 55) * 8
    }
    for (let i = 0, len = id2.length; i < len; i++) {
      num = id2.substr(i, 1)
      sum += num * (7 - i)
    }
  
    let last = 11 - sum % 11
    last = last.toString()
    switch (last) {
      case '10':
        last = 'A'
        break
      case '11':
        last = '0'
        break
    }
    return id3 === last
  }
}
  
export default IDCheck