import IDCheck from '@/utils/idcheck'
import {checkEmail, checkMobile, checkPhone} from '@/utils/validate'

export default {
  props: {
    isRequired: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    property: {
      immediate: true,
      handler(val) {
        if(this.extraRules) {
          this.rules = Object.assign(this.rules, this.extraRules)
        }
      }
    }
  },
  data() {
    const checkHKID = (rule, value, callback) => {
      // const regIdCard = /^((\s?[A-Za-z])|([A-Za-z]{2}))\d{6}(\([0−9aA]\)|[0-9aA])$/;
      if ( !IDCheck(value) ) {
        callback( new Error( '請輸入正確的身份證號碼' ))
      }
      callback()
    }
    return {
      requireValid: true,
      rules: {
        input: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        select: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        upload: [
          { required: true, message: this.$t('placeholder.upload'), trigger: ['blur'] }
        ],
        // motor
        class_id: [
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
          { required: true, trigger: ['blur'] }
        ],
        company_id: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        product_id: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        policy_type: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        client_type: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        issue_date: [
          { required: false}
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],

        effective_date: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_name: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_first_name: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_last_name: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_mobile: [
          { required: true, message: this.$t('placeholder.input'), trigger: 'blur' },
          { pattern:/^[23456789]\d{7}$/, message: '目前只支持中國香港的手機號碼',trigger: 'blur' }
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        policy_holder_email: [
          { required: false,message: '請選擇', trigger: [ 'blur'] },
          { pattern:/^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '請輸入正確的郵箱',trigger: [ 'blur']  },
        ],
        holder_company_email: [
          { required: false, message: 'Please enter 請輸入',trigger: [ 'blur'] },
          { pattern:/^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '請輸入正確的郵箱',trigger: [ 'blur']  },
        ],
        holder_company_phone:[
          { required: true, message: 'Please enter 請輸入',trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '請輸入正確的格式',trigger: 'blur' },
        ],
        holder_contact_phone:[
          { required: true, message: 'Please enter 請輸入', trigger: ['blur'] },
          { pattern:/^[456789]\d{7}$/, message: '目前只支持中國香港的手機號碼',trigger: 'blur' }
        ],
        holder_contact_email: [
          { required: true,  message: '請輸入',trigger: [ 'blur'] },
          { pattern:/^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '請輸入正確的郵箱',trigger: [ 'blur']  },
        ],
        holder_company_nature_id: [
          { required: true, message: 'Please select 請選擇',  trigger: [ 'blur','change'] },
        ],
        policy_holder_id_no: [
          { trigger: [ 'blur'], message: '請輸入',validator:checkHKID },
          { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_id_type: [
          { required: true, message: '請選擇', trigger: [ 'blur'] },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_title: [
          {  required: true, message: 'Please select 請選擇', trigger: ['blur','change'] }
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        policy_holder_birth: [
          {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        policy_holder_occ_id: [
          {  required: true, message: this.$t('placeholder.select'), trigger: ['blur','change'] }
        ],

        holder_company_name: [
          {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        holder_company_id_no: [
          {  required: true, message: 'Please enter 請輸入', trigger: ['blur'] }
        ],
        holder_contact_name: [
          { required: true, message: 'Please enter 請輸入', trigger: [ 'blur'] },
        ],
        agent_mobile_no:[
          { required: true, message: 'Please enter 請輸入', trigger: ['blur'] },
          { pattern:/^[456789]\d{7}$/, message: '目前只支持中國香港的手機號碼',trigger: 'blur' }
        ],
        base_premium: [
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
          { required: false, trigger: ['change', 'blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數',trigger: 'blur' },
        ],
        loading: [
          { required: false, trigger: ['blur'] },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        client_discount: [
          { required: false, trigger: [ 'blur'] },
        ],
        additional_premium: [
          { required: false, trigger: [ 'blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數',trigger: 'blur' },
        ],
        ia_levy_vl: [
          { required: false, trigger: ['blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數',trigger: 'blur' },
        ],
        amount_received: [
          { required: false, trigger: [ 'blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數',trigger: 'blur' },
        ],
        special_discount: [
          { required: false, trigger: [ 'blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數' ,trigger: 'blur'},
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        net: [
          { required: false, trigger: [ 'blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數',trigger: 'blur' },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        insurer_net: [
          // { validator: checkRequired, trigger: ['change', 'blur'] }
          { required: false, trigger: [ 'blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數' ,trigger: 'blur'},
        ],
        is_special: [
          { required: false, trigger: ['change', 'blur'] },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數',trigger: 'blur' },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        room: [
          { required: false },
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        floor: [
          { required: false },
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        block: [
          { required: false },
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        building: [
          { required: false },
        ],
        street: [
          { required: false },          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        district: [
          { required: false },          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        area: [
          { required: false },          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        region: [
          { required: false },          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        cover_type: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數' },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        hire_purchase_owner: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        hire_purchase_owner_id: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        estimated_vehicle_value: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        policy_send_to_hire_purchase_owner_date: [
          { required: false, trigger: [ 'blur'] },
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        own_damage: [
          { required: false, trigger: [ 'blur'] },
          // {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },

          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        parking: [
          // { validator: checkRequired, trigger: ['change', 'blur'] }
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },

        ],
        unnamed_driver: [
          // { validator: checkRequired, trigger: ['change', 'blur'] }
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},

        ],
        young_driver: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },

          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        inexperienced_driver: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},

        ],
        theft: [
          { required: false, trigger: ['change', 'blur'] },
          // {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},

          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        tppd: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },

          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        tppd_young_driver: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },

          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        tppd_inexperienced_driver: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },

        ],
        tppd_unnamed_driver: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},
        ],
        registration_mark: [
          // { required: false, trigger: ['blur'] },
          { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        searing_capacity: [
          { required: false, trigger: [ 'blur'] },
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        car_make_id: [
          { required: false, trigger: [ 'blur'] },
        ],
        chassis_number: [
          { required: false, trigger: ['blur'] },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        car_model: [
          { required: false, trigger: ['blur'] },
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        body_type_id: [
          { required: false, trigger: ['blur'] },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        engine_number: [
          { required: false, trigger: ['blur'] },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        ncd: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        // account
        vehicle_cylinder_capacity: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },
        ],
        // ticket
        year_of_manufacture: [
          { required: false, pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },
        ],
        name: [
          { required: false, trigger: [ 'blur'] },
          // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        ],
        // last_name: [
        //   { required: false, trigger: [ 'blur'] },
        //   // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        // ],
        // first_name: [
        //   { required: false, trigger: [ 'blur'] },
        //   // { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        // ],
        occ_id: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},
        ],
        birth: [
          { required: false, trigger: [ 'blur'] },
        ],
        driver_experience_id: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },
        ],
        id_type: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },
        ],
        // id_no: [
        //   {  trigger: [ 'blur'],required: false},
        // ],
        relationship_id: [
          { required: false, trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},
        ],
        remark: [
          { required: false, trigger: ['blur'],message: this.$t('placeholder.input'), },
        ],
        policy_no: [
          { required: false, trigger: [ 'blur'] },
        ],
        covernote_no: [
          { required: false, trigger: ['blur'] },
        ],
        policy_document_file_id: [
          { required: false, trigger: [ 'blur'] },
        ],
        covernote_document_file_id: [
          { required: false, trigger: [ 'blur'] },
        ],
        policy_receive_date: [
          { required: false, trigger: [ 'blur'] },
        ],
        policy_dispatch_date: [
          { required: false, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        policy_dispatch_mathed_id: [
          { required: false, trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' ,trigger: 'blur'},
        ],
        required_documents: [
          { required: false, trigger: [ 'blur'] },
        ],
        file_id: [
          { required: false, trigger: [ 'blur'] },
        ],
        nature: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        nationality_id: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        domestic_dob: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        domestic_id_type: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        domestic_id_no: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        period_of_cvr: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        monthly_salary: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        domestic_title: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        full_name: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        id_type_pic_path_id: [
          { required: true, message: this.$t('placeholder.upload'), trigger: ['blur'] }
        ],
        domestic_id_type_pic_path_id: [
          { required: true, message: this.$t('placeholder.upload'), trigger: ['blur'] }
        ],
        cert_of_inc_pic_path_id: [
          { required: true, message: this.$t('placeholder.upload'), trigger: ['blur'] }
        ],
        // Overseas Student
        country_id: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        is_full_time: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        // travel
        plan_type: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        pcy_expry_dt: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        class_type: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        no_of_audit: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        // benefits: [
        //   { required: true, message: this.$t('placeholder.select'), trigger: ['change', 'blur'] }
        // ],
        // working
        from_hk_to_id: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        // home fire
        prop_insured: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        bldg_typ: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        floor_area: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        coverage_id: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        prop_age: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        owner_liability_ind: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        // decoration
        contr_prd: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        bldg_age: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        tol_contr_sum: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
        ],
        mat_dam_cvr_ind: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        tp_lia_cvr_ind: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        outdoor_wk_ind: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        scaffolding_wk: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        occupied_as: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        con_nm: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        con_phone_no: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        contract_path_id: [
          { required: true, message: this.$t('placeholder.upload'), trigger: ['blur'] }
        ],
        sum_insured: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        ci_no:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],

        // reply
        quote_status: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],

        plan_id: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        code_disc_pct: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        base_prm: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        basic_prm: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        ia_levy_pct: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        spec_disc_pct: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
        ],
        handling_fee: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
        ],
        additional_charge: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        srd_pct: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
        ],
        loading_pct: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
        ],
        cfd_pct: [
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] }
        ],
        mib_pct: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
        ],
        sum_building:[
          // { required: false, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] },
          // { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數' },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字' },
        ],
        sum_content:[
          { required: false, message: this.$t('placeholder.input'), trigger: [ 'blur'] },

          // { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數' },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入数字',trigger: 'blur' },
        ],
        sum_stock:[
          { required: false, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
          // { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數' },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數',trigger: 'blur' },        ],
        no_of_employee:[
          { required: false, trigger: ['blur']},
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能输入正整数' ,trigger: 'blur'},
        ],
        total_contract_vl:[
          { required: false, message: this.$t('placeholder.input'), trigger: ['blur'] },
          // { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數' },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數',trigger: 'blur' },        ],
        contract_vl:[
          { required: false, message: this.$t('placeholder.input'), trigger: ['blur'] },
          // { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '請輸入正確格式，可保留兩位小數' },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數',trigger: 'blur' },        ],
        no_of_spouse:[
          { required: false, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數',trigger: 'blur' },
        ],
        no_of_kid:[
          { required: false, message: this.$t('placeholder.input'), trigger: ['blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數',trigger: 'blur' },
        ],
        no_of_older:[
          { required: false, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數',trigger: 'blur' },
        ],
        val_email:[
          { required: false, trigger: [ 'blur'] },
          { pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/ , message: '請輸入正確的郵箱',trigger: ['change', 'blur']  },
          // {  type: 'number', message: '只能输入数字' },
        ],
        start_time: [
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] }
        ],
        end_time: [
          { required: true, message: this.$t('placeholder.select'), trigger: [ 'blur'] }
        ],
        thumb_url: [
          { required: true, message: this.$t('placeholder.upload'), trigger: ['blur'] }
        ],
        url: [
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] }
        ],
        type:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
          {  pattern: /^[0-9]*[0-9][0-9]*$/, message: '只能輸入正整數',trigger: 'blur' },
        ],
        last_name:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
        ],
        first_name:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
        ],
        mobile_number:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
          { pattern:/^[2456789]\d{7}$/, message: '目前只支持中國香港的手機號碼',trigger: 'blur' }
        ],
        tel: [
          // { validator: checkRequired, trigger: ['change', 'blur'] },
          { required: false, message: this.$t('placeholder.input'), trigger: ['blur'] },
          { validator: checkMobile, trigger: ['blur'] },
          // { required: true, message: this.$t('placeholder.input'), trigger: ['change', 'blur'] }
        ],
        date:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
        ],
        handle_name:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
        ],
        cheque_no:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
        ],
        cheque_file_id:[
          { required: true, message: this.$t('placeholder.upload'), trigger: [ 'blur'] },
        ],
        bank_no_id:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
        ],
        account_no:[
          { required: true, message: this.$t('placeholder.input'), trigger: [ 'blur'] },
        ],
        receipt_file_id:[
          { required: true, message: this.$t('placeholder.upload'), trigger: ['blur'] },
        ],
        endorsement_effective_date:[
          { required: true, message: this.$t('placeholder.select'), trigger: ['blur'] },

        ],
        //create referrer
        contact_phone:[
          { required: true, message: this.$t('placeholder.input'), trigger: ['blur'] },

        ]
      }
    }
  }
}
