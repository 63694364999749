
export const checkMobile = (rule, value, callback) => {
  const valueResult = value !== null && value !== ''
  const reg = /^[2456789]\d{7}$/
  if(valueResult && !reg.test(value)) {
    callback(new Error('Please enter an valid phone No.'))
  }
  callback()
}

export const checkPhone = (rule, value, callback) => {
  const valueResult = value !== null && value !== ''
  const reg = /^[0123456789]\d{7}$/
  if(valueResult && !reg.test(value)) {
    callback(new Error('Please enter an valid phone No.'))
  }
  callback()
}

export const checkEmail = (rule, value, callback) => {
  const valueResult = value !== null && value !== ''
  const reg = /^[a-zA-Z0-9\._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  if(valueResult && !reg.test(value)) {
    callback(new Error('Please enter the correct email address'))
  }
  callback()
}
